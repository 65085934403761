import { render, staticRenderFns } from "./invioceaddress.vue?vue&type=template&id=11e5f479&scoped=true"
import script from "./invioceaddress.vue?vue&type=script&lang=js"
export * from "./invioceaddress.vue?vue&type=script&lang=js"
import style0 from "./invioceaddress.vue?vue&type=style&index=0&id=11e5f479&prod&lang=less&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "11e5f479",
  null
  
)

export default component.exports